<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editStock')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createStock')"
    >
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div>
                <b-row>
                    <template v-if="!form.id">
                        <b-col cols="12">
                            <zw-select-group v-model="form.warehouse_id"
                                             :options="getWarehouses()"
                                             name="warehouse"
                                             textField="name"
                                             valueField="id"
                                             :label-prefix="labelPrefix"
                                             @input="warehouseSelected"
                                             validate="required"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-select-group v-model="form.rack_num"
                                             v-if="form.warehouse_id"
                                             :options="racks[form.warehouse_id] || []"
                                             name="rack"
                                             textField="label"
                                             valueField="rack_num"
                                             :label-prefix="labelPrefix"
                                             @input="(val)=>{rackSelected(form.warehouse_id,val)}"
                                             validate="required"
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-select-group v-model="form.shelf_id"
                                             v-if="form.rack_num && shelves[form.warehouse_id]"
                                             :options="shelves[form.warehouse_id][form.rack_num] || []"
                                             name="shelve"
                                             textField="label"
                                             valueField="id"
                                             :label-prefix="labelPrefix"
                                             validate="required"
                            ></zw-select-group>
                        </b-col>
                    </template>
                    <b-col cols="12">
                        <zw-input-group v-model="form.warning_lvl"
                                        name="warning_lvl"
                                        :label-prefix="labelPrefix"
                                        type="number"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group v-model="form.ideal_lvl"
                                        name="ideal_lvl"
                                        :label-prefix="labelPrefix"
                                        type="number"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'StockModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                warning_lvl: 1,
                ideal_lvl: 1,
            },
            form: {},
            labelPrefix: 'stock.label.',
            racks: {},
            shelves: {},
        }
    },
    methods: {
        ...mapGetters('Stock', ['getWarehouses', 'getStock']),
        shown() {
            if (this.payload.id) {
                const warehouses = this.$store.dispatch('Stock/fetchWarehouses')
                const stock = this.$store.dispatch('Stock/fetchStock', this.payload.id)

                Promise.all([stock, warehouses])
                    .then(() => {
                        this.form = {
                            ...this.defaultForm,
                            ...JSON.parse(JSON.stringify(this.getStock()))
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            } else {
                const warehouses = this.$store.dispatch('Stock/fetchWarehouses')
                this.form = {
                    ...this.defaultForm,
                    article_id: this.payload.article_id
                }
                this.loading = false
            }
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Stock/saveStock', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        warehouseSelected(warehouseId) {
            this.loading = true
            const racks = this.$store.dispatch('Stock/fetchRacks', {warehouseId})

            Promise.all([racks])
                .then((data) => {
                    this.$set(this.racks, warehouseId, data[0].map(rack => {
                        rack.label = rack.rack_name + ' ' + rack.rack_num
                        return rack
                    }))
                })
                .finally(() => {
                    this.loading = false
                })
        },
        rackSelected(warehouseId, rackId) {
            this.loading = true
            const shelves = this.$store.dispatch('Stock/fetchShelves', {
                warehouseId,
                rackId,
                articleId: 0
            })

            Promise.all([shelves])
                .then((data) => {
                    if (!this.shelves[warehouseId]) {
                        this.$set(this.shelves, warehouseId, {})
                    }
                    this.$set(this.shelves[warehouseId], rackId, data[0].map(shelve => {
                        shelve.label = shelve.shelf_name + ' ' + shelve.shelf_num
                        return shelve
                    }))
                })
                .finally(() => {
                    this.loading = false
                })
        },
    }
}
</script>